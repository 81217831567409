import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetTags } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getWindow, mergeClasses } from "../../shared";
import { Scenario } from "../../common/logger/Logger";
import { IRecordingPlaybackInfo } from "../../core/slices/session.interface";
import { PlayerLoading, playerStyles } from "../PlayerLoading";
import { getLocale } from "../../core/localization/i18n";
import {
  ILogger,
  IScenarioEventData,
  LoggerLevels,
} from "../../common/logger/interface";
import { useLogger } from "../../common/logger/LoggerContext";
/// <reference path="./amp.d.ts" />

// if AMP script location is changed,
// CSP in Startup.cs and trusted type definition in trusted-security-policies.js needs to be updated as well
const ampScript =
  "https://amp.azure.net/libs/amp/latest/azuremediaplayer.min.js";
const ampStyle =
  "https://amp.azure.net/libs/amp/latest/skins/amp-default/azuremediaplayer.min.css";
const videoElementId = "azuremediaplayer";

export interface IAmpPlayerProps {
  publishTimeStamp: string;
  hasTranscript: boolean;
  playbackInfo?: IRecordingPlaybackInfo;
}

export const AmpPlayer: React.FunctionComponent<IAmpPlayerProps> = ({
  publishTimeStamp,
  hasTranscript,
  playbackInfo,
}) => {
  const window = getWindow();
  const logger = useLogger().logger;

  const playerClasses = playerStyles();
  const [isAmpScriptLoaded, setAmpScriptLoaded] = useState(false);
  const [isAmpPlayerLoaded, setIsAmpPlayerLoaded] = useState(false);
  const { t: i18n } = useTranslation();
  const locale = getLocale();

  useEffect(() => {
    logger.createScenario(Scenario.AmpSetup, {
      data: { publishTimeStamp },
    });
    const playbackScenario = logger.createScenario(Scenario.AmpPlayback, {
      data: { publishTimeStamp },
    });
    return () => {
      if (playbackScenario && !playbackScenario.isScenarioStopped()) {
        playbackScenario?.stop();
      }
    };
  }, [logger, publishTimeStamp]);

  useEffect(() => {
    if (!isAmpScriptLoaded || !playbackInfo || isAmpPlayerLoaded) {
      return;
    }

    bootstrapAmp(
      logger,
      locale,
      hasTranscript,
      i18n("recording_captions_on"),
      publishTimeStamp,
      playbackInfo
    );

    setIsAmpPlayerLoaded(true);
    const setupScenario = logger.findScenario(Scenario.AmpSetup);
    setupScenario?.mark("ampPlayerLoaded");
    setupScenario?.stop();
  }, [
    isAmpScriptLoaded,
    playbackInfo,
    isAmpPlayerLoaded,
    locale,
    publishTimeStamp,
    hasTranscript,
    i18n,
    logger,
  ]);

  const onChangeClientState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, addedTags: HelmetTags) => {
      if (!addedTags.scriptTags) {
        return;
      }
      addedTags.scriptTags[0].onload = () => {
        setAmpScriptLoaded(true);

        const setupScenario = logger.findScenario(Scenario.AmpSetup);
        setupScenario?.mark("ampScriptLoaded", undefined, {
          data: { publishTimeStamp },
        });
      };
      addedTags.scriptTags[0].onerror = () => {
        const setupScenario = logger.findScenario(Scenario.AmpSetup);
        setupScenario?.fail({
          message: "AMS script failed to load",
          data: { publishTimeStamp },
        });
      };
    },
    [logger, publishTimeStamp]
  );

  // Ensure playback scenario is ended when page is closed (eg. page reload, and close tab/window/browser).
  React.useEffect(() => {
    const beforeunload = () => {
      const playbackScenario = logger.findScenario(Scenario.AmpPlayback);
      if (playbackScenario && !playbackScenario.isScenarioStopped()) {
        playbackScenario?.stop();
      }
    };
    window?.addEventListener("beforeunload", beforeunload);
    return () => {
      window?.removeEventListener("beforeunload", beforeunload);
    };
  }, [logger, window]);

  return (
    <>
      <Helmet>
        <link href={ampStyle} rel="stylesheet" />
      </Helmet>
      <Helmet
        script={[
          {
            src: ampScript,
          },
        ]}
        onChangeClientState={onChangeClientState}
      />
      {(!isAmpScriptLoaded || !playbackInfo) && <PlayerLoading />}
      {!!playbackInfo && isAmpScriptLoaded && (
        <div>
          <video
            id={videoElementId}
            data-testid="amp-player"
            // needed for ios support, works on 'html5' tech
            // AMP initialization removes 'playsinline' for 'azureHtml5JS' tech
            playsInline
            className={mergeClasses(
              "azuremediaplayer amp-default-skin amp-big-play-centered",
              playerClasses.container
            )}
            width="100%"
          />
        </div>
      )}
    </>
  );
};

function bootstrapAmp(
  logger: ILogger,
  locale: string,
  hasTranscript: boolean,
  captionsLabel: string,
  publishTimeStamp: string,
  playbackInfo: IRecordingPlaybackInfo
) {
  const playerInstance = amp(
    videoElementId,
    buildAmpOptions(locale, hasTranscript, captionsLabel)
  );
  addAmpPlayerEventListeners(playerInstance, publishTimeStamp);
  setAmpPlayerSrc(playerInstance, playbackInfo);

  function buildAmpOptions(
    locale: string,
    hasTranscript: boolean,
    captionsOnLabel: string
  ) {
    return {
      // html5 maps to HLS and azureHtml5JS maps to dash. To learn more - https://www.vidbeo.com/blog/hls-vs-dash
      // html5 is used by safari on ios, all other browsers use azureHTML5JS
      // https://docs.microsoft.com/en-us/azure/media-services/azure-media-player/azure-media-player-playback-technology
      techOrder: ["azureHtml5JS", "html5"],
      nativeControlsForTouch: false, // added to disable native touch controls - Skin controls used instead on touch devices
      autoplay: true,
      preload: "auto",
      controls: true,
      logo: { enabled: false },
      language: locale,
      imsc1CaptionsSettings: hasTranscript
        ? [
            {
              label: captionsOnLabel,
              srclang: "",
              startShowing: false,
            },
          ]
        : undefined,
      playbackSpeed: {
        enabled: true,
        speedLevels: [
          // speed levels mimic the ones in the onePlayer
          { name: "2x", value: 2.0 },
          { name: "1.8x", value: 1.8 },
          { name: "1.5x", value: 1.5 },
          { name: "1.3x", value: 1.3 },
          { name: "1x", value: 1.0 },
          { name: "0.8x", value: 0.8 },
        ],
      },
    };
  }

  function addAmpPlayerEventListeners(
    playerInstance: amp.Player,
    publishTimeStamp: string
  ) {
    const ampEvents = [
      amp.eventName.error,
      amp.eventName.loadstart,
      amp.eventName.loadeddata,
      amp.eventName.loadedmetadata,
      amp.eventName.start,
      amp.eventName.play,
      amp.eventName.pause,
      amp.eventName.resume,
      amp.eventName.ended,
    ];
    ampEvents.forEach((eventName) => {
      addAmpPlayerEventListener(publishTimeStamp, playerInstance, eventName);
    });
  }

  function addAmpPlayerEventListener(
    publishTimeStamp: string,
    playerInstance: amp.Player,
    eventName: string
  ) {
    playerInstance.addEventListener(eventName, function () {
      logger.logTrace(LoggerLevels.info, `AMP event. EventName: ${eventName};`);

      const playbackScenario = logger.findScenario(Scenario.AmpPlayback);
      let eventStatus: string | undefined = undefined;
      const eventData: Partial<IScenarioEventData> = {
        data: { publishTimeStamp },
      };
      if (eventName === amp.eventName.error) {
        const errorDetails = playerInstance.error();
        eventData.message = `AMP player error. Code: ${errorDetails.code}; message: ${errorDetails.message};`;
        eventData.data = {
          ...eventData.data,
          errorCode: errorDetails.code,
          errorMessage: errorDetails.message,
        };
        if (isTransientNetworkError(errorDetails.code)) {
          eventStatus = "transientNetworkError";
          playbackScenario?.mark(eventName, eventStatus, eventData);
          playbackScenario?.stop();
        } else if (isDecodeError(errorDetails.code)) {
          eventStatus = "decodeError";
          playbackScenario?.mark(eventName, eventStatus, eventData);
          playbackScenario?.stop();
        } else if (isSrcNotSupportedError(errorDetails.code)) {
          eventStatus = "srcNotSupportedError";
          playbackScenario?.mark(eventName, eventStatus, eventData);
          playbackScenario?.stop();
        } else {
          eventStatus = "failed";
          playbackScenario?.fail(eventData);
        }
      } else {
        playbackScenario?.mark(eventName, eventStatus, eventData);
      }
    });
  }

  function setAmpPlayerSrc(
    playerInstance: amp.Player,
    playbackInfo: IRecordingPlaybackInfo
  ) {
    playerInstance.src(
      [
        {
          src: playbackInfo.streamingUrl,
          type: "application/vnd.ms-sstr+xml",
          protectionInfo: [
            {
              type: "AES",
              authenticationToken: `Bearer=${playbackInfo.token}`,
            },
          ],
        },
      ],
      []
    );
  }
}

const isTransientNetworkError: (errorCode: number) => boolean = (code) => {
  if (!code) {
    return false;
  }

  const highLevelErrorCodeMask = (1 << 28) - 1;
  const highLevelErrorCode = highLevelErrorCodeMask & code;

  return (
    highLevelErrorCode === amp.errorCode.networkErrTimeout ||
    highLevelErrorCode === amp.errorCode.networkErrError ||
    highLevelErrorCode === amp.errorCode.networkErrAbort
  );
};

// Due to corruption problem or because the video used features browser did not support.
const isDecodeError: (errorCode: number) => boolean = (code) => {
  if (!code) {
    return false;
  }

  const highLevelErrorCodeMask = (1 << 28) - 1;
  const highLevelErrorCode = highLevelErrorCodeMask & code;

  return highLevelErrorCode === amp.errorCode.decodeErrUnknown;
};

// File format not supported.
const isSrcNotSupportedError: (errorCode: number) => boolean = (code) => {
  if (!code) {
    return false;
  }

  const highLevelErrorCodeMask = (1 << 28) - 1;
  const highLevelErrorCode = highLevelErrorCodeMask & code;

  return highLevelErrorCode === amp.errorCode.srcErrUnknown;
};
